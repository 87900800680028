import React from 'react';

export default ({width= 64, circleColor="#ffdd1f", spinnerColor="rgba(0, 0, 0, 1)"}) => {
  let viewBox = (width > 64 ) ? () => (`0 0 ${width} ${width}`): () => (`0 0 64 64`);

  return (
    <svg className="loadingKeywords" width={width} height={width} viewBox={viewBox()}
         fill="#8533FF" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32ZM4.90769 32C4.90769 46.9627 17.0373 59.0923 32 59.0923C46.9627 59.0923 59.0923 46.9627 59.0923 32C59.0923 17.0373 46.9627 4.90769 32 4.90769C17.0373 4.90769 4.90769 17.0373 4.90769 32Z"
        fill={circleColor} />
      <path
        d="M32 61.5462C32 62.9014 30.8998 64.0098 29.5485 63.906C25.3467 63.5831 21.2415 62.4327 17.4723 60.5122C12.9728 58.2196 9.07973 54.8946 6.11146 50.8091C3.14318 46.7237 1.18396 41.9937 0.393974 37.0059C-0.39601 32.0182 0.0056751 26.9142 1.56619 22.1115C3.1267 17.3087 5.80173 12.9434 9.37257 9.37259C12.9434 5.80175 17.3087 3.12671 22.1114 1.5662C26.9142 0.00567929 32.0181 -0.39601 37.0059 0.39397C41.1841 1.05573 45.1814 2.53799 48.7705 4.74657C49.9247 5.45682 50.1634 7.00025 49.3668 8.09665C48.5702 9.19304 47.0406 9.42545 45.8767 8.73131C42.9216 6.96903 39.6512 5.7818 36.2382 5.24124C32.0153 4.57241 27.6942 4.9125 23.628 6.23368C19.5618 7.55487 15.866 9.81965 12.8428 12.8429C9.81964 15.866 7.55486 19.5618 6.23368 23.628C4.91249 27.6942 4.57241 32.0154 5.24124 36.2382C5.91007 40.461 7.56882 44.4656 10.0819 47.9245C12.5949 51.3834 15.8909 54.1984 19.7003 56.1394C22.7793 57.7082 26.123 58.6701 29.5495 58.9813C30.8991 59.1038 32 60.1909 32 61.5462Z"
        fill={spinnerColor} />
    </svg>
  );
};


